.title{
    margin-bottom: 24px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}
.row{
    display: flex;
    gap: 32px;
    justify-content: space-between;
}
.single{
    width: 50%;
    margin-bottom: 80px;
}
.text{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
}
.m_btn{
    margin-bottom: 16px;
}
.m_left{
    margin-left: 10px;
}
.redText{
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    margin-left: 4px;
    margin-right: 4px;
}
.btnStyle{
    padding: 12px 24px;
    border-radius: 4px;
    background: #DC0003;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.1px;
    text-transform: uppercase;
    cursor: pointer;
    width: fit-content;
    margin: auto;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}