.left{
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 80px;
}
.single{
    width: 100%;
}
.subTitle{
    color: rgba(0, 0, 0, 0.60);
    margin-bottom: 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; 
    letter-spacing: 0.1px;
}

.dataStyle{
    margin-left: 18px;
    color: rgba(0, 0, 0, 0.60);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; 
    letter-spacing: 0.1px;
}
.liTitle{
    color: rgba(0, 0, 0, 0.60);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; 
    letter-spacing: 0.1px;
    margin-top: 24px;
}
.liText{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.1px;
    margin-bottom: 8px;
    margin-left: -18px;
}

.list{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.1px;
    margin-bottom: 8px;
}

.boldText{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
}