.modalTitle{
    padding-top: 8px;
    padding-bottom: 8px;
    color: #141414;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;
}
.buttons{
    display: flex;
    justify-content: flex-end;
}
.btnStyle{
    padding: 12px 24px;
    cursor: pointer;
    border: none;
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DC0003;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
}