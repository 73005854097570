.goHomeDivRegister{
    display: flex;
    margin-bottom: 42px;
    cursor: pointer;
}
.goHomeDiv{
    display: flex;
    margin-bottom: 150px;
    cursor: pointer;
}
.title{
    font-family: RobotoSlab_Regular, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: #000;
    margin-bottom: 32px;
}
.inputDiv{
    margin-bottom: 20px;
    max-width:432px;
    width: 100%;
}

.inputStyle{
    color: #000;
    max-width: 432px;
    width: 100%;
    padding: 16px 12px 14px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #D2D2D2;
    background: #F4F4F4;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.inputStyle:focus{
    outline: none;
}
.inputStyle::placeholder{
    color: #525252;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.invalid{
    border: 2px solid #CB2D25;
}
.error{
    font-size: 14px;
    line-height: 24px;
    color: #CB2D25;
    max-width: 520px;
    width: 100%;
    margin-top: 7px;
}

.btnStyle{
    padding:12px 24px;
    border-radius: 4px;
    background: #DC0003;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    cursor: pointer;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.googleBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}

.alreadyDiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 432px;
    width: 100%;
    margin-top: 34px;
    margin-bottom: 24px;
}
.alreadyHave{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.alreadyBtn{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    text-decoration-line: underline;
    cursor: pointer;
}

.lineDivs{
    max-width: 432px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 19px;
}
.lineLeft{
    height: 1px;
    max-width: 191px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.60);
}
.or{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.googleBtn{
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.60);
    max-width: 432px;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
.btnInside{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.60);
    width: 100%;
    display: flex;
    justify-content: center;
}
.leftImage{
    background-image:  url('../../assets/images/LoginImage.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.25);
    width: 50%;
    height: auto;
}
.rightLogoDiv{
    padding-top:32px;
    padding-left: 32px;
    padding-bottom: 60px;
    background: #EAEFF2;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
}
.goHomeLoginDiv{
    display: flex;
    cursor: pointer;
}
.loginTitle{
    font-family: RobotoSlab_Regular;
    margin-bottom: 40px;
    margin-top: 40px;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; 
    letter-spacing: 0.1px;
    color: #000;
}
.labelStyle{
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #000;
}
.checkboxPart{
    margin-top: 40px;
    max-width: 624px;
    width: 100%;
}
.checkBoxDiv{
   display: flex;
   margin-bottom: 24px;
}
.checkboxStyle{
    width: 24px;
    height: 24px;
}
.checkboxLabel{
    margin-left: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.60);
}

.loginText{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    max-width: 624px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
}
.underLined{
    text-decoration-line: underline;
    margin-left:5px;
    margin-right: 5px;
}