.information{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 80px;
}
.single{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.title{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; 
    letter-spacing: 0.1px;
    color: #000;
    margin-bottom: 50px;
}

.inputDiv{
    margin-bottom: 24px;
    max-width:432px;
    width: 100%;
}

.inputStyle{
    color: #000;
    max-width: 432px;
    width: 100%;
    padding: 16px 12px 14px;
    border-radius: 4px 4px 0px 0px;  
    border-bottom: 1px solid #D2D2D2;
    background: #F4F4F4;   
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.inputStyle:focus{
    outline: none;
}
.inputStyle::placeholder{
    color: #525252;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.invalid{
    border: 2px solid #CB2D25;
}

.labelStyle{
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #000;
}
.btnStyle{
    background-color: #FFF;
    color: #DC0003;
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #DC0003;
    width: fit-content;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.1px;
    cursor: pointer;
    text-transform: uppercase;
}

.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.subTitle{
    margin-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.60);
}
.switcher{
    border-radius: 8px;
    border: 1px solid #000; 
    padding: 24px 40px 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    max-width: fit-content;
    gap: 40px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 16px;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: -3px;
    background-color: #7A7A7A;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: -3px;
    background-color: #000;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #7A7A7A;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #7A7A7A;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .titleSwitcher{
    margin-bottom: 4px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; 
    letter-spacing: 0.1px;
    color: #000;
  }

  .textSwitcher{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; 
    letter-spacing: 0.1px;
  }