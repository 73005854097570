.subTitle{
    margin-bottom: 16px;
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.1px;
}
.red{
    margin-right: 4px;
    margin-left: 4px;
    color: #DC0003;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    cursor: pointer;
}
.olStyle{
    color: rgba(0, 0, 0, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: 20px;
}