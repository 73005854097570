.row{
    display: flex;
    justify-content: space-between;
    gap: 32px;
    flex-wrap: wrap;
}
.left{
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 80px;
}
.single{
    width: 100%;}

.title{
    margin-bottom:24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    color: #000;
}

.list{
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    margin-bottom: 16px;
    margin-left:14px;
    cursor: pointer;
}

.text{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.60);
}
.underlined{
    cursor: pointer;
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-decoration-line: underline;
}