.row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}
.icon{
    width: 40px;
    height: 40px;
    cursor: pointer;
}
.inputDiv{
    margin-bottom: 24px;
    max-width:432px;
    width: 100%;
}
.inputStyle{
    color: #000;
    max-width: 432px;
    width: 100%;
    padding: 16px 12px 14px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #D2D2D2;
    background: #F4F4F4;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.inputStyle:focus{
    outline: none;
}
.inputStyle::placeholder{
    color: #525252;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    font-family: Montserrat_Regular,sans-serif;
}

textarea::placeholder{
    font-family: Montserrat_Regular,sans-serif;
    color: #525252;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.invalid{
    border: 2px solid #CB2D25;
}
.btnStyle{
    width: fit-content;
    padding:12px 24px;
    border-radius: 4px;
    background: #DC0003;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    cursor: pointer;
}
.btnStyle:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.error{
    font-size: 14px;
    line-height: 24px;
    color: #CB2D25;
    max-width: 520px;
    width: 100%;
    margin-top: 7px;
}