.row{
    display: flex;
    gap: 32px;
    justify-content: space-between;
}
.single{
    width: 50%;
}
.block{
    display: flex;
    flex-direction: column;
    width:100%;
    margin-bottom: 80px;
}
.title{
    margin-bottom: 24px;
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}
.text{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
}
.redText{
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: 0.1px;
    text-decoration-line: underline;
    cursor: pointer;
}
.m_left{
    margin-left: 30px;
}
.redSpan{
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-left: 4px;
}