.wholeDivs{
    margin-top: 128px;
    margin-bottom: 160px;
}
.wholeDiv{
    padding: 24px;
    border-radius: 24px;
    background: #EAEFF2;
    margin-bottom: 80px;
}
.titleDiv{
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}
.titleLeft{
    display: flex;
    gap:16px;
    align-items: center;
}
.titleLeftSingle{
    display: flex;
    gap:16px;
    align-items: center;
    margin-bottom: 40px;
}
.bigIcon{
    width: 48px;
    height: 48px;
}
.title{
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 150% */
    letter-spacing: 0.1px;
    color: #000;
    font-family: RobotoSlab_Regular,sans-serif;
}
.singleDivs{
    padding:24px;
    width: 100%;
    border-radius: 16px;
    background: #FFF;
}
.singleDiv{
    border-bottom: 1px solid #EAEFF2;
    padding-bottom: 19px;
    padding-top: 19px;
    display: flex;
}
.singleDivs :first-child{
    padding-top: 0;
}
.singleDivs:last-child{
    padding-bottom: 0;
    border-bottom: unset;
}

.singleInside{
    display: flex;
    flex-direction: column;
}
.singleTitle{
    margin-bottom: 2px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #000;
}
.singleData{
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.60);
}
.singleBtnsDiv{
    display: flex;
    gap: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
}
.singleBtn{
    width: 59px;
    border-radius: 2px;
    border: 1px solid #DC0003;
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    background: #FFEBEB;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #DC0003;
    cursor: pointer;
}
.singleCommentsDiv{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.60);
}
.view{
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #DC0003;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
    text-transform: uppercase;
    cursor: pointer;
}
.view:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}
.homeUp{
    position: relative;
    /*background-image:  url('../../assets/images/HomeImage1.png');*/
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
    height: 498px;
    display: flex;
}

.homeInside{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.homeTitle{
    max-width: 580px;
    width: 100%;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0.1px;
    color: #FFF;
    font-family: RobotoSlab_Regular,sans-serif;
    margin-bottom: 8px;
}
.homeText{
    max-width: 580px;
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #FFF;
    padding-bottom: 24px;
    border-bottom: 1px solid  #FFF;
    margin-bottom: 24px;
}
.btns{
    display: flex;
    max-width: 580px;
    width: 100%;
    gap: 12px;
    margin-bottom: 16px;
}
.btnStyle{
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: #DC0003;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #FFF;
}
.dates{
    max-width: 580px;
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.details{
    max-width: 580px;
    width: 100%;
    display: flex;
    gap: 20px;
}
.detail{
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.1px;
    color: #FFF;
}
.detailIcon{
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.moreBtn{
    margin-top: 24px;
    padding: 12px 24px;
    background:transparent;
    color: #DC0003;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    cursor: pointer;
}
