.headerUp{
    padding-top: 16px;
    padding-bottom: 16px;
}
.headerInside{
    display: flex;
    justify-content: space-between;
}
.headerInside{
    display: flex;
}
.headerLeft{
    display: flex;
    align-items: center;
}
.headerRight{
    display: flex;
    gap: 80px;
}
.headerRightDiv{
    display: flex;
    align-items: center;
}
.headerText{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #000;
}
.headerMiddle{
    background: #EAEFF2;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    position: relative;
}
.middleInsideTop{
    display: flex;
    gap:40px;
    border-bottom: 1px solid #FFF;
    padding-bottom: 24px;
}
.middleInsideBottom{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 24px;
}
.logo{
    color: #DC0003;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
}
.feedback{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:12px 24px;
    border-radius: 4px;
    border: 1px solid #DC0003;
    color: #DC0003;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
}
.headerBtn{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #DC0003;
    padding:12px 24px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    white-space: nowrap;
}
.headerItem{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor:pointer;
}
.activeItem{
    color: #DC0003;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    cursor:pointer;
}

.searchDiv{
    border-radius: 4px;
    border: 1px solid #DC0003;
    padding: 12px;
    background: #FFF4F4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.searchInput{
    width: 100%;
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.1px;
    background: none;
    color: #000;
}
.searchInput::placeholder{
    color: rgba(0, 0, 0, 0.50);
}

.dropdown{
    width: 300px;
    position: absolute;
    top: 80px;
    right: 270px;
    z-index: 10;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 3px 5px 0px rgba(20, 20, 20, 0.16), 0px 1px 18px 0px rgba(20, 20, 20, 0.08), 0px 6px 10px 0px rgba(20, 20, 20, 0.12);
}

.singleUp{
    white-space: nowrap;
    display:flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    cursor: pointer;
}
.singleUp:hover{
    font-weight: 600;
    color: #DC0003;
}
.singleUp:hover img  {
    content: url('../../assets/icons/RedSettings.svg');
}
.singleDown{
    white-space: nowrap;
    display:flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    cursor: pointer;
}
.singleDown:hover{
    font-weight: 600;
    color: #DC0003;
}
.singleDown:hover img  {
    content: url('../../assets/icons/RedOut.svg');
}

