.footerUp{
    padding-top: 80px;
    padding-bottom: 80px;
    background: #EAEFF2;
}
.footerInside{
    display: flex;
    justify-content: space-between;
}
.footerDown{
    padding-top: 16px;
    padding-bottom: 16px;
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1px;
    width: 100%;
}
.logo{
    color: #000;
    font-family: RobotoSlab_Regular,sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.1px;
}
.footerItem{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 16px;
}