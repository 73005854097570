.title{
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 24px;
}

.row{
    display: flex;
    gap: 32px;
    justify-content: space-between;
    margin-bottom: 80px;
}
.single{
    width: 50%;
}
.bold{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-right: 5px;
}
.text{
    color: rgba(0, 0, 0, 0.60);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
}
.subTitle{
    margin-bottom: 24px;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.1px;
}
.m_btn{
    margin-bottom: 24px;
}
.m_left{
    margin-left: 18px;
}
.applyBtn{
    padding: 12px 24px;
    border-radius: 4px;
    border: 1px solid #DC0003;
    color: #DC0003;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin: auto;
    cursor: pointer;
    background: #FFF;
}
.applyBtn:hover{
    box-shadow: 0px 0px 16px 0px #6B9BF2;
}