@font-face {
  font-family: RobotoSlab_Regular;
  src: url('app/assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf');
}

@font-face {
  font-family: Montserrat_Regular;
  src: url('app/assets/fonts/Motserrat/Montserrat-Regular.ttf');
}

* {
  box-sizing: border-box;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}


img {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  width: 100%;
  font-family: Montserrat_Regular,sans-serif !important;
}

header {
  display: flex;
  flex-direction: column;
}

.container{
  margin: 0 auto;
  max-width: 1390px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.whole{
  display: flex;
}
.left{
  background-color:#EAEFF2;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: -1px 0px 8px 0px rgba(0, 0, 0, 0.25);
  width: 55%;
  height: 100vh;
}
.right{
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-left: 116px;
  width: 45%;
}
.logo{
  margin-top: 120px;
  font-size: 160px;
  font-style: normal;
  font-weight: 400;
  line-height: 200px;
  color: #DC0003;
  margin-bottom: 80px;
}
.title{
  margin-bottom: 16px;
  font-family: RobotoSlab_Regular, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  color: #000;
}
.subTitle{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  max-width: 580px;
  width: 100%;
  color: rgba(0, 0, 0, 0.60);
}

.iconStyle{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.goHome{
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.60);
}

.swiperHome{
  max-width:1920px;
  position: unset;
  width: 100%;
}

.parentBtn {
  padding: 60px 50px;
  display: flex;
}
.swiper-button-next-new{
  display: flex;
  justify-content:center;
  align-items: center;
  padding:14px;
  border-radius: 8px;
  cursor: pointer;
  content:url("./app/assets/icons/RightGrey.svg")
}
.swiper-button-next{
  display: none;
}
.swiper-button-prev{
  display: none;
}

.swiper-button-prev-new{
  display: flex;
  justify-content:center;
  align-items: center;
  padding:14px;
  border-radius: 8px;
  cursor: pointer;
  content:url("./app/assets/icons/LeftGrey.svg")
}
.parentBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 50px 64px;
  z-index: 99;
}

.swiper-button-prev-disabled{
  cursor: not-allowed;
}
.swiper-button-next-disabled{
  cursor: not-allowed;
}
.modalInside{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modalTitle{
  font-family: Montserrat_Regular,sans-serif;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.48px;
  margin-bottom: 24px;
}
.maxWidth{
  max-width: 400px;
  width: 100%;
}