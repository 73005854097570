.settingsWhole{
    padding-top: 60px;
    padding-bottom: 160px;
}

.titleSettings{
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px; 
    letter-spacing: 0.1px;
    font-family: RobotoSlab_Regular;
    color: #000;
    width: 100%;
    margin-bottom: 24px;
}
.below{
    display: flex;
    gap: 80px;
    margin-bottom: 80px;
}
.itemWhole{
    display: flex;
    align-items: center;
    position: relative;
}
.item{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    cursor: pointer;
}
.active{
    color: #DC0003;
    font-weight: 600;
}
.dropdown{
    width: 664px;
    position: absolute;
    top: 35px;
    z-index: 10;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 3px 5px 0px rgba(20, 20, 20, 0.16), 0px 1px 18px 0px rgba(20, 20, 20, 0.08), 0px 6px 10px 0px rgba(20, 20, 20, 0.12);
}
.dropdownCareers{
    width: 316px;
    position: absolute;
    top: 35px;
    z-index: 10;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 3px 5px 0px rgba(20, 20, 20, 0.16), 0px 1px 18px 0px rgba(20, 20, 20, 0.08), 0px 6px 10px 0px rgba(20, 20, 20, 0.12);
}
.single{
    white-space: nowrap;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.10);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #000;
    cursor: pointer;
}
.single:hover{
    font-weight: 600;
    color: #DC0003;
}

